<template>
    <section class="midi-controllers-wrapper">
        <h4>MIDI Controllers</h4>
        <div v-if="midiIsAvailable">
            <div v-if="midiInputsAreAvailable" class="mt-4">
                <div>
                    <label class="d-block" for="controllers">Choose a USB MIDI controller:</label>
                    <select class="p-1 mt-2" name="controllers" @change="selectMidiInput">
                        <option value="null">Select a MIDI controller from the list...</option>
                        <option v-for="(midiInput) in midiInputs" :value="midiInput"
                            :selected="midiInputSelected == midiInput">
                            {{ midiInput }}
                        </option>
                    </select>
                </div>
                <div v-if="midiInputSelected != 'null'" class="mt-4">
                    <div class="row">
                        <span class="slider-name filmstro-yellow">Momentum</span>
                        <div class="slider-cc-wrapper">
                            <label for="momentum-cc">CC</label>
                            <input type="number" name="momentum-cc" min="0" max="127" :value="assignedCC[0]"
                                @change="updateAssignedCCManually" data-ccindex="0">
                        </div>
                        <div class="slider-midi-learn-button">
                            <button @click="updateActiveMidiLearn" data-ccindex="0"
                                v-tooltip="`To use MIDI learn function: (1) Click MIDI learn (2) move your chosen controller (3) click MIDI learn again`"
                                :class="{ 'clicked': activeMIDILearnClicked[0], '': !activeMIDILearnClicked[0] }">MIDI
                                learn</button>
                        </div>
                    </div>
                    <div class="row">
                        <span class="slider-name filmstro-blue">Depth</span>
                        <div class="slider-cc-wrapper">
                            <label for="depth-cc">CC</label>
                            <input type="number" name="depth-cc" min="0" max="127" :value="assignedCC[1]"
                                @change="updateAssignedCCManually" data-ccindex="1">
                        </div>
                        <div class="slider-midi-learn-button">
                            <button @click="updateActiveMidiLearn" data-ccindex="1"
                                :class="{ 'clicked': activeMIDILearnClicked[1], '': !activeMIDILearnClicked[1] }">MIDI
                                learn</button>
                        </div>
                    </div>
                    <div class="row">
                        <span class="slider-name filmstro-red">Power</span>
                        <div class="slider-cc-wrapper">
                            <label for="power-cc">CC</label>
                            <input type="number" name="power-cc" min="0" max="127" :value="assignedCC[2]"
                                @change="updateAssignedCCManually" data-ccindex="2">
                        </div>
                        <div class="slider-midi-learn-button">
                            <button @click="updateActiveMidiLearn" data-ccindex="2"
                                :class="{ 'clicked': activeMIDILearnClicked[2], '': !activeMIDILearnClicked[2] }">MIDI
                                learn</button>
                        </div>
                    </div>
                    <div class="row">
                        <span class="slider-name">Play/Pause</span>
                        <div class="slider-cc-wrapper">
                            <label for="playpause-cc">CC</label>
                            <input type="number" name="playpause-cc" min="0" max="127" :value="assignedCC[3]"
                                @change="updateAssignedCCManually" data-ccindex="3">
                        </div>
                        <div class="slider-midi-learn-button">
                            <button @click="updateActiveMidiLearn" data-ccindex="3"
                                :class="{ 'clicked': activeMIDILearnClicked[3], '': !activeMIDILearnClicked[3] }">MIDI
                                learn</button>
                        </div>
                    </div>
                    <div class="row">
                        <span class="slider-name">Stop</span>
                        <div class="slider-cc-wrapper">
                            <label for="stop-cc">CC</label>
                            <input type="number" name="stop-cc" min="0" max="127" :value="assignedCC[4]"
                                @change="updateAssignedCCManually" data-ccindex="4">
                        </div>
                        <div class="slider-midi-learn-button">
                            <button @click="updateActiveMidiLearn" data-ccindex="4"
                                :class="{ 'clicked': activeMIDILearnClicked[4], '': !activeMIDILearnClicked[4] }">MIDI
                                learn</button>
                        </div>
                    </div>
                    <div class="mt-4 midi-controller-helper-text">
                        <p>A MIDI CC (control change) message consists of a MIDI channel, CC number and value.</p>
                        <p class="mt-2">Filmstro accepts any MIDI channel and CC number. Your controller
                            should send a value of 0-127.</p>
                        <p class="mt-2"> For Play/Pause, set your controller to toggle CC. For
                            Stop, set your controller to momentary CC.</p>
                    </div>
                    <div class="mt-4">
                        <label class="d-block" for="controller-behaviour">Controller behaviour:</label>
                        <select class="p-1 mt-2" name="controller-behaviour" @change="selectControllerBehaviour">
                            <option value="off" :selected="controllerBehaviourSelected == 'off'">Off</option>
                            <option value="pickup" :selected="controllerBehaviourSelected == 'pickup'">Pickup</option>
                            <option value="valuescaling" :selected="controllerBehaviourSelected == 'valuescaling'">Value
                                scaling</option>
                        </select>
                    </div>
                    <div class="mt-4 midi-controller-helper-text">
                        <p>Off: value will jump when MIDI controller is moved.</p>
                        <p class="mt-2">Pickup: value will not change until MIDI controller reaches the current slider
                            value.</p>
                        <p class="mt-2">Value Scaling: MIDI controller will be mapped to the available slider range.</p>
                    </div>
                </div>
            </div>
            <div v-else class="no-controllers filmstro-yellow mt-4">
                <p>There are no USB MIDI controllers connected.</p>
            </div>
        </div>
        <div v-else class="no-controllers filmstro-yellow mt-4">
            <p>To use MIDI, please use Chrome 43+, Edge 79+, or Opera 30+ (desktop).</p>
        </div>
        <div class="flex justify-center mt-4 gap-10 align-center">
            <filmstro-icon name="info-icon" />
            <a target="_blank" class="underline text-gray" href="https://filmstro.com/handbook/midi-controllers/">Learn
                More</a>
        </div>
    </section>
</template>

<script>
import { WebMidi } from "webmidi";
import * as Sentry from "@sentry/vue";

import useAudioEngine from '@/composables/useAudioEngine';

const { AudioEngine, is_playing } = useAudioEngine();

import Bowser from "bowser";
export default {
    data() {
        return {
            midiIsAvailable: false,
            midiInputsAreAvailable: false,
            midiInputs: [],
            midiInputSelected: localStorage.getItem("midiInputSelected") ? localStorage.getItem("midiInputSelected") : 'null',
            controllerBehaviourSelected: localStorage.getItem("controllerBehaviourSelected") ? localStorage.getItem("controllerBehaviourSelected") : 'off',
            assignedCC: localStorage.getItem("assignedCC") ? JSON.parse(localStorage.getItem("assignedCC")) : [16, 17, 18, 19, 20],
            activeMIDILearn: 'null',
            activeMIDILearnClicked: [false, false, false, false, false],
            previousCCValue: ['null', 'null', 'null'],
            controlStartTime: null,
            controlTimeout: null
        }
    },
    beforeUnmount() {
        if (this.controlTimeout) {
            clearTimeout(this.controlTimeout);
        }
    },
    methods: {
        startWebMidi() {
            // Start webmidi.js and trigger the onWebMidiEnabled() function when ready
            WebMidi
                .enable()
                .then(this.onWebMidiEnabled)
                .catch(err => {
                    console.log("WebMidi couldn't be enabled", err);
                    Sentry.captureException(err);
                });
        },
        onWebMidiEnabled() {
            // Get the MIDI inputs
            if (WebMidi.inputs.length < 1) {
                console.log("No MIDI inputs detected");
                this.midiInputsAreAvailable = false;
            } else {
                WebMidi.inputs.forEach((device) => {
                    this.midiInputs.push(device.name);
                });
                this.midiInputsAreAvailable = true;
                console.log("MIDI inputs detected.");
            }

            // If a midi input is already selected (eg. from a previous session or switching between browse and edit), check it's still valid
            if (this.midiInputSelected != 'null') {
                if (WebMidi.getInputByName(this.midiInputSelected)) {
                    // The input selected is still valid, so it'll need a listener
                    this.addNewListener(this.midiInputSelected);
                } else {
                    // The input selected is not valid, so reset it
                    this.midiInputSelected = 'null';
                    localStorage.setItem("midiInputSelected", this.midiInputSelected);
                }
            }
        },
        selectMidiInput(event) {
            // A MIDI input has been selected
            let inputSelected = event.target.value

            // If there was previously a midi input selected and it had a listener, remove it
            if (this.midiInputSelected != 'null' && WebMidi.getInputByName(this.midiInputSelected).hasListener("controlchange")) {
                WebMidi.getInputByName(this.midiInputSelected).removeListener();
            }

            this.midiInputSelected = inputSelected;
            localStorage.setItem("midiInputSelected", this.midiInputSelected);

            this.addNewListener(inputSelected);
        },
        addNewListener(inputSelected) {
            // Only add a new listener if an actual input
            if (inputSelected != 'null') {
                // If an old listener is in place, remove it
                if (WebMidi.getInputByName(inputSelected).hasListener("controlchange")) {
                    WebMidi.getInputByName(inputSelected).removeListener();
                }
                // Add a new listener
                WebMidi.getInputByName(inputSelected).addListener("controlchange", e => {
                    let ccNumber = e.dataBytes[0];
                    let ccValue = e.dataBytes[1];

                    this.updateAssignedCC(ccNumber);
                    this.useCC(ccNumber, ccValue);
                });
            }
        },
        useCC(ccNumber, ccValue) {
            // If received CC number matches an assigned CC number, update the relevant app function
            for (let i = 0; i < 5; i++) {
                if (this.assignedCC[i] === ccNumber) {

                    // Use the CC to affect the sliders
                    if (i < 3) {
                        window.using_midi = true;
                        // Get the slider value (scale is 0 to 1)
                        let sliderValue = Number(document.getElementById('daw-' + i).value);
                        // Start timing when control begins
                        if (!this.controlStartTime) {
                            this.controlStartTime = { time: AudioEngine.normalized_current_time, value: sliderValue }
                        }

                        // Clear any existing timeout
                        if (this.controlTimeout) {
                            clearTimeout(this.controlTimeout);
                        }


                        if (this.controllerBehaviourSelected == 'pickup') {
                            this.applyPickup(i, sliderValue, ccValue);
                        } else if (this.controllerBehaviourSelected == 'valuescaling') {
                            this.applyValueScaling(i, sliderValue, ccValue);
                        } else {
                            this.applyJump(i, ccValue);
                        }
                        this.previousCCValue[i] = ccValue;

                        //debounce this for adding keyframs using midi
                        this.controlTimeout = setTimeout(() => {
                            if (this.controlStartTime) {
                                let end = { time: AudioEngine.normalized_current_time, value: sliderValue };
                                if( is_playing.value ){
                                    document.dispatchEvent(new CustomEvent('score-slider-mouseup', { detail: { start: this.controlStartTime, end } }));
                                }
                                // Reset timing state
                                this.controlStartTime = null;
                            }
                            window.using_midi = true;
                        }, 500);

                    }
                    if (i === 3) {
                        filmstroapp.togglePlay();
                    }
                    if (i === 4) {
                        filmstroapp.stopPlay();
                    }
                }
            }
        },
        applyJump(slider, ccValue) {
            let newAbsoluteValue = ccValue / 127;
            if (slider === 0) {
                filmstroapp.setMomentum(newAbsoluteValue.toFixed(2), false);
            }
            if (slider === 1) {
                filmstroapp.setDepth(newAbsoluteValue.toFixed(2), false);
            }
            if (slider === 2) {
                filmstroapp.setPower(newAbsoluteValue.toFixed(2), false);
            }
            document.querySelector(`#ic-r1-${slider}`).click();
        },
        applyPickup(slider, sliderValue, ccValue) {
            // PICKUP MODE
            // Do we have a previous CC value? If not, skip this one
            if (this.previousCCValue[slider] != 'null') {
                let sliderCCValue = Math.round(sliderValue * 127);
                let pickedUp = false;
                let window = 10;

                // Is value going up (or else down)?
                if (ccValue > this.previousCCValue[slider]) {
                    // Going up
                    // Only set the slider if the incoming CC is in a same value window
                    if (ccValue >= sliderCCValue && ccValue < sliderCCValue + window) {
                        pickedUp = true;
                    } else {
                        pickedUp = false;
                    }
                } else if (ccValue < this.previousCCValue[slider]) {
                    // Going down
                    // Only set the slider if the incoming CC is in a same value window
                    if (ccValue <= sliderCCValue && ccValue > sliderCCValue - window) {
                        pickedUp = true;
                    } else {
                        pickedUp = false;
                    }
                }

                if (pickedUp) {
                    let newAbsoluteValue = ccValue / 127;
                    if (slider === 0) {
                        filmstroapp.setMomentum(newAbsoluteValue.toFixed(2), false);
                    }
                    if (slider === 1) {
                        filmstroapp.setDepth(newAbsoluteValue.toFixed(2), false);
                    }
                    if (slider === 2) {
                        filmstroapp.setPower(newAbsoluteValue.toFixed(2), false);
                    }
                }
            }
            document.querySelector(`#ic-r1-${slider}`).click();

        },
        applyValueScaling(slider, sliderValue, ccValue) {
            // VALUE SCALING
            // Do we have a previous CC value? If not, skip this one
            if (this.previousCCValue[slider] != 'null') {
                // Note that we need a 1-128 range, not 0-127
                let sliderCCValue = Math.round(sliderValue * 127) + 1;
                let currentValue = ccValue + 1;
                let previousValue = this.previousCCValue[slider] + 1;

                // Is value going up (or else down)?
                if (currentValue > previousValue) {
                    // Going up
                    if (sliderCCValue <= 127) {
                        let controllerRangeAvailable = 128 - previousValue;

                        // Current value as a % of previous value
                        let percentageChange = (100 / controllerRangeAvailable) * (currentValue - previousValue);

                        // Find percentage change of slider range
                        let sliderChange = ((128 - sliderCCValue) / 100) * percentageChange;
                        let newSliderValue = sliderCCValue + sliderChange;

                        let newAbsoluteValue = newSliderValue / 128;

                        // Always move up by something
                        if (sliderValue + 0.01 > newAbsoluteValue) {
                            newAbsoluteValue = sliderValue + 0.01;
                        }

                        // Handle the extremes
                        if (newAbsoluteValue > 1) {
                            newAbsoluteValue = 1;
                        }

                        if (slider === 0) {
                            filmstroapp.setMomentum(newAbsoluteValue.toFixed(2), false);
                        }
                        if (slider === 1) {
                            filmstroapp.setDepth(newAbsoluteValue.toFixed(2), false);
                        }
                        if (slider === 2) {
                            filmstroapp.setPower(newAbsoluteValue.toFixed(2), false);
                        }
                    }
                } else if (currentValue < previousValue) {
                    // Going down
                    if (sliderCCValue >= 0) {
                        // Current value as a % of previous value
                        let percentageChange = (100 / previousValue) * currentValue;

                        // Apply % change to slider
                        let newSliderValue = (sliderCCValue / 100) * percentageChange;

                        let newAbsoluteValue = newSliderValue / 128;

                        // Always move down by something
                        if (sliderValue - 0.01 < newAbsoluteValue) {
                            newAbsoluteValue = sliderValue - 0.01;
                        }

                        // Handle the extremes
                        if (newAbsoluteValue < 0.025) {
                            newAbsoluteValue = 0;
                        }

                        if (slider === 0) {
                            filmstroapp.setMomentum(newAbsoluteValue.toFixed(2), false);
                        }
                        if (slider === 1) {
                            filmstroapp.setDepth(newAbsoluteValue.toFixed(2), false);
                        }
                        if (slider === 2) {
                            filmstroapp.setPower(newAbsoluteValue.toFixed(2), false);
                        }
                    }
                }
            }
            document.querySelector(`#ic-r1-${slider}`).click();
        },
        updateAssignedCC(ccNumber) {
            // Only update the assigned CC if the MIDI learn button is active
            if (this.activeMIDILearn != 'null') {
                this.assignedCC[this.activeMIDILearn] = ccNumber;
                localStorage.setItem("assignedCC", JSON.stringify(this.assignedCC));
            }
        },
        updateAssignedCCManually(event) {
            let ccindex = event.target.dataset.ccindex;

            // Don't allow CCs of < 0 and > 127
            let unclampedCC = event.target.value;
            let clampedCC = Math.min(Math.max(unclampedCC, 0), 127);

            this.assignedCC[ccindex] = clampedCC;
            localStorage.setItem("assignedCC", JSON.stringify(this.assignedCC));
        },
        updateActiveMidiLearn(event) {
            let ccindex = event.target.dataset.ccindex;

            this.activeMIDILearnClicked[ccindex] = !this.activeMIDILearnClicked[ccindex];
            // Set all other buttons to not clicked (false)
            for (let i = 0; i < 5; i++) {
                if (i != ccindex) {
                    this.activeMIDILearnClicked[i] = false;
                }
            }

            // If the button has been turned off, set the active button back to null
            if (this.activeMIDILearnClicked[ccindex] == false) {
                this.activeMIDILearn = 'null';
            } else {
                this.activeMIDILearn = ccindex;
            }
        },
        selectControllerBehaviour(event) {
            let controllerBehaviourSelected = event.target.value

            this.controllerBehaviourSelected = controllerBehaviourSelected;
            localStorage.setItem("controllerBehaviourSelected", controllerBehaviourSelected);

            // Reset the previous CC values to avoid old data causing jumps
            this.previousCCValue = ['null', 'null', 'null'];
        },
    },
    mounted() {
        let browser = Bowser.getParser(window.navigator.userAgent);
        let browserName = browser.getBrowserName();
        if (navigator.requestMIDIAccess && browserName != "Firefox") {
            this.startWebMidi();
            this.midiIsAvailable = true;
        } else {
            this.midiIsAvailable = false;
        }
    }
}
</script>

<style scoped>
* {
    font-family: 'Nunito Sans';
}

input,
select {
    background-color: #20202B;
    border: 2px solid #3D3C44;
    color: white;
}

select {
    width: 100%;
}

button {
    background-color: #20202B;
    border: 2px solid #3D3C44;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    color: white;
}

.no-controllers {
    border: 2px solid #3D3C44;
    border-radius: 0.5rem;
    padding: 1rem;
    text-align: center;
}

.d-block {
    display: block;
}

.row {
    display: flex;
    margin-top: 0.5rem;
}

.slider-midi-learn-button,
.slider-cc-wrapper,
.slider-name {
    width: 33.3333%;
}

.slider-midi-learn-button {
    display: flex;
    justify-content: end;
}

.clicked {
    color: var(--filmstro-yellow) !important;
    background-color: #4A4A4A !important;
    border: 2px solid #4A4A4A !important;
}

.slider-cc-wrapper label {
    margin-right: 0.5rem;
    font-size: 0.9rem;
}

.slider-cc-wrapper input {
    width: 4rem;
    padding: 0.25rem 0.5rem;
    align-items: center;
}

.slider-name {
    display: inline-flex;
    align-items: center;
    font-size: 0.9rem;
}

.midi-controllers-wrapper {
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
}

.midi-controller-helper-text p {
    font-size: 0.75rem;
    line-height: 1rem;
    color: #808080;
}

.p-1 {
    padding: 0.25rem;
}

.mt-4 {
    margin-top: 1.5rem;
}

.mt-2 {
    margin-top: 0.5rem;
}
</style>