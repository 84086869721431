<template>
    <modal class="settings-modal" v-show="preferences_modal_visible" @close="closeModal" :show_close_button="true"
        :show_the_content="preferences_modal_visible">
        <template v-slot:body>
            <h4 class="settings-title text-center">Advanced Preferences</h4>

            <div class="settings-header">
                <button class="clear-button settings-header-item" :class="{'active':activeTab=='export'}" @click="activeTab='export'">
                    <icon name="export" :size="50" :activeHover="true" :active="activeTab=='export'" />
                    <span>Export</span>
                </button>
                <button class="clear-button settings-header-item" :class="{'active':activeTab=='length'}" @click="activeTab= 'length'">
                    <icon name="length" :size="50" :activeHover="true" :active="activeTab=='length'" />
                    <span>Length</span>
                </button>
                <button class="clear-button settings-header-item" :class="{'active':activeTab=='midi'}" @click="activeTab= 'midi'">
                    <icon name="midi" :size="50" :activeHover="true" :active="activeTab=='midi'" />
                    <span>MiDi</span>
                </button>
                <button class="clear-button settings-header-item" :class="{'active':activeTab=='shortcuts'}" @click="store.commit('setShowShortcutsKeyboardModal',true)">
                    <icon name="shortcuts-keyboard" :activeHover="true" :size="60" :active="activeTab=='about'" />
                    <span>Shortcuts</span>
                </button>
                <button class="clear-button settings-header-item" :class="{'active':activeTab=='about'}" @click="activeTab= 'about'">
                    <icon name="about" :size="50" :activeHover="true" :active="activeTab=='about'" />
                    <span>About</span>
                </button>
            </div>




            <div class="settings-wrapper">

                <div class="settings-block-wrapper" v-if="activeTab=='length'">
                    <div class="setting-block mb-2">
                        <h3 class="setting-block-title">Use this to fine-tune setting a custom length</h3>
                        <div class="setting-top">
                            <div class="track-setting-header mb-0">
                                <h3 for="">Match Type</h3>
                                <div class="track-setting-description">Best Match is recommended.</div>
                            </div>
                        </div>
                        <AlgorithmMode />
                        <div class="track-settings-info mt-1">
                            <filmstro-icon name="info-icon"/>
                            <a target="_blank" href="https://filmstro.com/app-settings">Learn More</a>
                        </div>
                    </div>

                    <div class="setting-block">
                        <TrackSettings />
                    </div>

                </div>




                <div class="settings-block-wrapper" v-if="activeTab=='export'">
                    <div class="setting-block">
                        <h3 class="setting-block-title">If your export is not playing as expected, please change the sample rate below</h3>
                        <div class="setting-top">

                            <div class="track-setting-header">
                                <h3 for="">Sample Rate</h3>
                                <ToggleSwitch v-model="sampleRate" size="small" leftLabel="48000" rightLabel="44100" @change="onSampleRateChange($event)" />
                                <div class="track-setting-description">Toggle Sample rate to control the picth/speed</div>
                                <div class="track-settings-info">
                                    <filmstro-icon name="info-icon"/>
                                    <a target="_blank" href="https://filmstro.com/handbook/export-settings/">Learn More</a>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div class="settings-block" v-if="activeTab=='midi'">
                    <MidiControllers />
                </div>
                <div class="settings-block" v-if="activeTab=='about'">
                    <AboutFilmstro/>
                </div>

            </div>
        </template>
    </modal>
</template>

<script setup>
import { computed, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import TrackSettings from '@/components/Edit/TrackSettings.vue';
import AlgorithmMode from '@/components/AlgorithmMode.vue';
import AboutFilmstro from '@/components/AboutFilmstro.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import MidiControllers from '@/components/MidiControllers.vue'
import { logger } from '@/includes/helpers';
let store = useStore();
let closeModal = () => {
    store.commit('setPreferencesModalVisible', false);
}

const activeTab = ref('length');

let preferences_modal_visible = computed(() => store.state.preferences_modal_visible)
let _local_saved_sample_rate = store.getters['edit/sample_rate'];
let sample_rate = ref(parseInt(_local_saved_sample_rate));
const onSampleRateChange = (v)=>{
    logger({'Sample rate CHANGED':v});
    toggleSampleRate(v)
}
const sampleRate = computed({
  get() {
    return sample_rate.value == 44100
  },
  set(value) {
    // Dispatch the appropriate action when toggled
    const newMode = value ? 'best_match' : 'closest_match';
    store.dispatch('edit/updateAlgorithmMode', newMode);

    localStorage.setItem('sample_rate', sample_rate.value);
    store.commit('edit/setSampleRate', sample_rate.value);

  }
});

let toggleSampleRate = (event) => {
    let element = event.target;

    if (element.checked) {
        sample_rate.value = 44100;
        // wrapper.querySelector('.toggle-input-label span').innerText = 44100;
    } else {
        sample_rate.value = 48000;
        // wrapper.querySelector('.toggle-input-label span').innerText = 48000;
    }
    localStorage.setItem('sample_rate', sample_rate.value);
    store.commit('edit/setSampleRate', sample_rate.value);
}


</script>

<style lang="scss">
.settings-modal {



    .settings-header {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        margin-bottom: 30px;
    }

    .settings-header-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3px;
        color:#9B9B9B;
        &.active{
            color:var(--filmstro-yellow)
        }
    }

    .modal-content {
        background-color: #07131b;
        min-width: 450px;
    }

    .modal-body{
        width: 500px;
    }

    .modal-dialog {
        max-width: 700px !important;
    }


    .setting-block {
        text-align: left;

        label {
            color: #7b848b;
            font-size: 0.875rem;
            font-weight: 600;
        }

        .setting-top {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-block: 20px;
        }

        .toggle-input-wrapper {
            display: inline-flex;
            flex: 1;
            margin-left: auto;
            width: 100%;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
        }

        small {
            font-size: 12px;
            display: block;
            margin-bottom: 3px;
            margin-top: 4px
        }
    }

    .btn-close {
        color: white !important;
        top: 15px;
        right: 20px;
    }
}
</style>