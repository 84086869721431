<template>
    <div class="algorithm-mode">
        <ToggleSwitch v-model="isClosest" left-label="Closest" right-label="Best" size="small"/>
        <div>
          <small class="atooltip-content">Closest match prioritizes mathicng your requested music length as closely as possible, while 'best match' focuses on the most musical result and may be a second or so longer or shorter than uour requested length.</small>
        </div>
    </div>
</template>

<script setup>
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
const store = useStore()

const algorithm_mode = computed(()=>store.getters['edit/algorithm_mode']);

const isClosest = computed({
  get() {
    return store.getters['edit/algorithm_mode'] === 'best_match';
  },
  set(value) {
    console.log(value);
    // Dispatch the appropriate action when toggled
    const newMode = value ? 'best_match' : 'closest_match';
    store.dispatch('edit/updateAlgorithmMode', newMode);
  }
});
</script>

<style lang="scss" scoped>
.algorithm-mode{
    display: flex;
    flex-direction: column;
    gap: 10px;
    label{
      display: flex;
      align-items: center;
      gap: 5px;
    }
}

.tooltip-toggler {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
}

.tooltip-content {
    opacity: 0;
    z-index: 99;
    color: black;
    width: 190px;
    display: block;
    font-size: 11px;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;
    background: rgba(251,251,251,0.9);
    border: 1px solid rgba(34,34,34,0.9);
    box-shadow: 0 0 3px rgba(0,0,0,0.5);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    position: absolute;
    right: -80px;
    bottom: 40px;
}

.tooltip-content:before,.tooltip-content:after {
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(251,251,251,0.9);
    position: absolute;
    bottom: -10px;
    left: 43%;
}

.tooltip-toggler:hover .tooltip-content,a:hover .tooltip-content {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}


</style>